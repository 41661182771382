@import 'var';

.header {
  width: 100%;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  background: $baseColor;
  z-index: 99;
  font-family: 'Archivo', sans-serif;

  a {
    text-decoration: none;
  }

  .header__title {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 25px;
    background: #008080;
    transition: 1.5s all;

    h4 {
      padding-left: 5px;
      padding-top: 2px;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: $whiteColor;
    }
  }

  .header__body {
    padding: 0 25px;
    .header-container {
      margin: auto;
      max-width: 1611px;
      display: flex;
      align-items: center;
      // margin-left: 120px;
      // background-color: black;
    }
  }

  &.sticky {
    .header__title,
    .header-top {
      display: none;
    }
  }
}

.header-container__logo-section {
  display: flex;
  align-items: center;

  .header-logo {
    margin-right: 30px;
    @media screen and (max-width: 1279px) {
      display: none;
    }
  }

  .header-menu {
    display: flex;
    align-items: center;
    margin-right: 30px;
    position: relative;

    .header-menu-content {
      padding-left: 10px;
      font-style: normal;
      font-weight: 600;
      font-size: 10px;
      line-height: 10px;
      color: $whiteColor;

      span {
        display: block;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
      }
    }
  }

  .header-menu-service {
    padding: 25px 0;
    &:hover {
      .header-dropdown-service-wrap {
        display: block;
      }
    }
  }

  .header-menu-category {
    padding: 25px 0;
    &:hover {
      .header-dropdown-category-wrap {
        display: block;
      }
    }
  }
}

.header-dropdown-category-wrap {
  position: absolute;
  top: 55px;
  left: -20px;
  background-image: url('../images/Partial/bg_dropdown4.png');
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100% 100%;
  padding: 20px 2px 5px 2px;
  display: none;
  min-width: 365px;
  z-index: 20;
}

.header-dropdown-category {
  background: $whiteColor;
  border-radius: 10px;
  //overflow: hidden;

  .category-product__menu__li {
    max-width: 365px;
  }
  .banner__category-product{
    width: 100%;
    @media screen and (max-width: 1024px) {
      display: block;
    }
  }
}

.header-dropdown-service-wrap {
  position: absolute;
  top: 55px;
  left: -40px;
  background-image: url('../images/Partial/bg_dropdown3.png');
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100% 100%;
  padding: 25px 2px 5px 2px;
  display: none;
  width: 210px;
  z-index: 20;
}

.header-dropdown-service {
  background: $whiteColor;
  border-radius: 10px;
  overflow: hidden;

  &__item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
    padding: 10px 10px;

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      color: #3f3b3b;
      padding-left: 10px;
    }

    &:hover {
      background: #d4f4f4;
    }

    &:last-child {
      border-bottom: none;
    }
  }
}

.header-container__search-section {
  flex: 1;
  padding: 35px 0;
  position: relative;

  .header-search-box {
    background: #ffffff;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    border-radius: 50px;
    display: flex;
    align-items: center;
    overflow: hidden;
    padding: 5px;
    z-index: 30;
    position: relative;
    max-width: 1100px;

    .input-box {
      border: none;
      flex: 1;
      padding: 0 20px;
      font-size: 14px;
      line-height: 19px;
      color: #767474;
    }

    .input-box:focus {
      outline: none;
    }

    .icon-search {
      width: 37px;
      height: 37px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $searchColorHome;
      border-radius: 20px;
    }
  }

  .header-suggestion-search {
    position: absolute;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    overflow: hidden;
    padding: 7.5px 20px 0 0;
    bottom: 0;
    z-index: 10;
    height: 35px;
    left: 0;
    width: 75%;

    .suggestion-item {
      height: 20px;
      white-space: nowrap;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 15px;
      color: $whiteColor;
      padding: 3px 15px;
      margin-bottom: 10px;
      border-right: 1px solid $whiteColor;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    .suggestion-item:last-child {
      border-right: none;
    }
  }

  .header-search-box-drop-down {
    position: absolute;
    top: 50px;
    z-index: 20;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    padding: 30px 25px 15px 25px;
    left: 0;
    border-radius: 15px;
    display: none;
    width: 100%;

    @media screen and (max-width: 1280px) {
      max-height: 75vh;
      overflow-y: auto;
    }

    &.show {
      display: block;
    }

    &__title {
      font-weight: 700;
      font-size: 18px;
      line-height: 25px;
      color: #3f3b3b;
      margin-bottom: 12px;
    }

    &__suggestion {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      a {
        cursor: pointer;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #008080;
        padding: 7px 7px;
        background: #d4f4f4;
        border-radius: 5px;
        margin-right: 12px;
        margin-bottom: 15px;
      }
    }

    &__history {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin: 10px 0px;
      max-height: 500px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 5px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #ffffff;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #cdcaca;
        border-radius: 5px;
      }

      .search-result-item {
        cursor: pointer;
        border-bottom: 1px solid #80808070;
        padding-bottom: 10px;
        width: 100%;

        span {
          margin-left: 10px;
        }
      }

      .search-result-item:last-child {
        border-bottom: none;
      }

      .spinner {
        width: 10px;
        height: 10px;
      }
    }

    &__banner {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      img {
        // cursor: pointer;
        height: 120px;
        margin-right: 10px;
        margin-bottom: 5px;
      }
    }

    &__divider {
      width: 100%;
      height: 1px;
      background: #d2cbcb;
      margin: 10px 0;
    }

    &__popular-product {
      @media screen and (max-width: 1279px) {
        .col-4 {
          width: 100%;
        }
      }
    }

    &__popular-product__item {
      display: flex;
      // align-items: center;
      margin-bottom: 15px;
      cursor: pointer;

      img {
        width: 45px;
        height: 45px;
        border: 1px solid #e5e5e5;
        box-sizing: border-box;
        border-radius: 5px;
        overflow: hidden;
      }

      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #3f3b3b;
        flex: 1;
        padding-left: 8px;
      }
    }

    &__popular-category {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }

    &__popular-category__item {
      width: 20%;
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 0 12px;
      margin-bottom: 10px;
      cursor: pointer;

      @media screen and (max-width: 1279px) {
        width: 50%;
      }

      img {
        width: 70px;
        height: 70px;
        border: 1px solid #e5e5e5;
        box-sizing: border-box;
        border-radius: 5px;
        overflow: hidden;
      }

      p {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: #3f3b3b;
        padding-top: 8px;
      }
    }
  }
}

.header-container__info-section {
  display: flex;
  align-items: center;

  .section-content-text {
    margin-top: 5px;

    &--orange {
      // margin-top: 10px;
      // border: 1px solid #ffffff;
      border-radius: 10px;
      // background: #008081;
      // padding: 0 10px;
      font-weight: bold;
    }

    &--normal {
      &:hover {
        cursor: pointer;
      }
    }
  }

  .section-content-icon {
    position: relative;

    .item-badge {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      position: absolute;
      bottom: 12px;
      right: -20px;
      background: $orangeColor;
      border-radius: 15px;
      overflow: hidden;

      p {
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        color: $whiteColor;
      }
    }

    .header-icon {
      &:hover {
        cursor: pointer;
      }
    }
  }

  .section-content-btn {
    button {
      background: none;
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      color: #ffffff;
      border: none;
      padding: 0;
      display: block;
      text-align: left;

      &:focus {
        outline: none;
      }
    }
  }

  .section-content-drop-list-wrap {
    position: absolute;
    top: 60px;
    left: -40px;
    background-image: url('../images/Partial/bg_dropdown.png');
    background-repeat: no-repeat;
    background-position: center top;
    padding-top: 32px;
    display: none;
  }

  .section-content-order-drop-list-wrap {
    position: absolute;
    top: 60px;
    left: -40px;
    background-image: url('../images/Partial/bg_dropdown2.png');
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100% 100%;
    padding: 20px 10px 10px 10px;
    display: none;
  }

  .section-content-order-drop-list {
    overflow: hidden;
    background: $whiteColor;
    padding: 10px 5px;

    &__search-box {
      box-sizing: border-box;
      background: #e0e0e0;
      border-radius: 50px;
      display: flex;
      align-items: center;
      overflow: hidden;
      padding: 5px 5px 5px 10px;
      margin-bottom: 10px;

      .input-box {
        border: none;
        flex: 1;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #767474;
        background: #e0e0e0;
      }

      .input-box:focus {
        outline: none;
      }

      .icon-search {
        cursor: pointer;
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $searchColorHome;
        border-radius: 25px;
        cursor: pointer;

        img {
          width: 15px;
        }
      }
    }

    &__table {
      width: 100%;
      margin-bottom: 10px;

      th {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #767474;
        //width: 50%;
      }

      &__item__order {
        display: flex;
        align-items: center;
        background: #fb800f;
        border-radius: 5px;
        padding: 2px 2px;
        margin-top: 10px;

        p {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          padding-left: 5px;

          color: #ffffff;
        }
      }

      &__item__total {
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;

        /* Gray 1 */

        color: #333333;
      }
    }

    &__btn {
      display: flex;
      justify-content: center;
      border-top: 1px solid #bdbdbd;
      padding-top: 10px;

      a {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;

        /* Main color */

        color: #20abad;
      }
    }
  }

  .section-content-drop-list {
    border-radius: 10px;
    overflow: hidden;
    background: $whiteColor;

    &__item {
      display: flex;
      align-items: center;
      width: 215px;
      padding: 10px 0;
      border-bottom: 1px solid #e0e0e0;
      cursor: pointer;
      padding-right: 15px;

      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        /* identical to box height, or 100% */
        color: #3f3b3b;
        flex: 1;
      }

      span {
        font-weight: 700;
        font-size: 18px;
        line-height: 30px;
        color: #f0592d;
      }

      &:hover {
        background: #d4f4f4;
      }
    }

    &__item__icon {
      display: flex;
      justify-content: center;
      width: 50px;
    }
  }
}

.header-container__info-section__user {
  margin-left: 30px;
  font-size: 12px;
  line-height: 16px;
  color: $whiteColor;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;

  &:hover {
    .section-content-drop-list-wrap {
      display: block;
    }
  }

  span {
    display: block;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
  }
}

.header-container__info-section__order {
  margin-left: 30px;
  font-size: 12px;
  line-height: 16px;
  color: $whiteColor;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;

  &:hover {
    .section-content-order-drop-list-wrap {
      display: block;
    }
  }

  span {
    display: block;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
  }
}

.header-container__info-section__cart {
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  line-height: 19px;
  color: $whiteColor;
  border: none;
  background: none;

  span {
    display: block;
    font-weight: 800;
    color: $orangeColor2;
  }
}

.header-top {
  display: flex;
  // padding: 0 25px;
  padding: 10px 0 0 0px;
  @media screen and (max-width: 1279px) {
    flex-wrap: wrap;
    padding-left: 25px;
    padding-top: 10px;
  }

  .header-top__left {
    display: inherit;
    -moz-box-align: center;
    align-items: center;
    color: #ffffff;
    .header-logo-2 {
      display: none;

      @media screen and (max-width: 1279px) {
        display: block;
      }
    }
    .header-top__img {
      margin-right: 11px;
    }
    .navbar {
      .navbar-nav {
        .nav-item {
          padding-right: 1.563rem;
          .dropdown-menu {
            position: absolute !important;
            right: 0;
            .row {
              select {
                font-size: 0.875rem;
              }
              .btn-select {
                background-color: $baseColorLight;
                color: #ffffff;
                border-radius: 50px;
              }
            }
          }
        }

        .nav-link {
          background-color: transparent;
          border: none;
          color: #ffffff !important;
          outline: 0;
          box-shadow: none;
          padding-left: 0.5rem;
          img {
            margin-left: 0.5rem;
          }
        }

        .dropdown-toggle::after {
          display: none;
        }

        .form-control {
          -webkit-appearance: menulist;
          -moz-appearance: menulist;
          appearance: menulist;
        }
      }
    }
  }
  .header-top__right {
    display: inherit;
    right: 0;
    margin-left: auto;
    -moz-box-align: center;
    align-items: center;

    a:not(:last-child) {
      padding: 0 10px;
      border-right: 1px solid #ffffff;
      font-size: 15px;
    }

    a,
    p {
      color: #ffffff;
      font-size: 0.75rem;
      display: -moz-box;
      display: flex;
      -moz-box-align: center;
      align-items: center;
      line-height: 17px;
      img {
        height: 17px;
        margin-right: 10px;
      }
    }

    @media screen and (max-width: 820px) {
      margin-left: 0;
      margin-top: 10px;

      a:first-child {
        padding-left: 0;
      }
    }

    .notify-unread {
      color: #f0592d;
      margin-left: 5px;
      font-weight: bold;
    }

    .group-lang {
      position: relative;
      width: 140px;
      .group-lang__btn-lang {
        display: -moz-box;
        display: flex;
        -moz-box-align: center;
        align-items: center;
        cursor: pointer;
        border: none;
        background-color: transparent;

        &:focus {
          box-shadow: none;
        }
        .group-lang__flag-icon {
          padding-left: 5px !important;
          height: 12px;
        }
        .group-lang__dropdown-icon {
          width: 12px;
          height: 7px;
        }
        .group-lang__text {
          display: flex;
          -moz-box-align: center;
          align-items: center;
          padding-right: 10px;
          padding-left: 2px !important;
          border-right: 0 !important;
          font-size: 15px;
        }
      }

      .dropdown-toggle::after {
        display: none;
      }
      .group-lang__dropdown {
        position: absolute;
        top: 35px;
        width: 120px;
        height: fit-content;
        padding: 0 10px;
        background-color: #fff;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        border-radius: 12px;
        z-index: 1;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        flex-direction: column;
        -moz-box-pack: end;
        justify-content: flex-end;

        .group-lang__lang_item {
          display: -moz-box;
          display: flex;
          cursor: pointer;
          margin: 0;
          border-top: 1px solid #e5e5e5;
          width: 100%;
          p {
            color: #000 !important;
            margin: 12px 0;
          }
        }
      }
    }
  }
}
.fs-12 {
  font-size: 0.75rem;
}
