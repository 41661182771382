.support-screen {
  margin: auto;
  max-width: 1611px;
  padding: 25px 10px;
  .support-screen__breadcrumbs {
    span,
    a {
      font-size: 0.875rem;
      color: #008080;
    }
  }

  .img-box-about {
    text-align: center;
    img {
      display: inline-block;
      vertical-align: middle;
      max-width: 100%;
      height: auto;
    }
  }

  .brd-bt {
    border-bottom: 1px dotted;
    margin: 45px 0px 30px 0px;
  }

  .support-screen__item__title {
    p {
      color: #333333;
      font-size: 1.875rem;
      font-weight: bold;
    }
    .support-screen__item__title--blue {
      margin-top: 10px;
      color: #20abad;
      font-size: 3.125rem;
    }
  }

  .support-screen__main {
    // display: flex;
    // margin: 30px 0;
    .support-screen__main__sidebar {
      display: flex;
      width: 20%;
      ul {
        width: 100%;
        li {
          display: flex;
          line-height: 22px;
          padding: 22px 0;
          a {
            color: #000000;
            font-weight: 600;
            font-size: 1rem;
            display: flex;
            align-items: center;
            width: 100%;
            img {
              margin-left: auto;
            }
          }
          &.active {
            p {
              color: #20abad;
              font-weight: bold;
              font-size: 1rem;
              display: flex;
              align-items: center;
              width: 100%;
              height: 33px;
            }
            img {
              display: none;
            }
          }
        }
        li:not(:last-child) {
          border-bottom: 1px solid #d2cbcb;
        }
      }
    }
    .support-screen__main__content {
      display: flex;
      max-width: 1600px;
      padding: 25px 0px 0px 0px;
      margin-left: auto;
      margin-right: auto;
      @media screen and (max-width: 1440px) {
        width: 85%;
      }

      .delivery-policy-screen {
        width: 100%;
        .delivery-policy-screen__title {
          position: relative;
          p {
            font-size: 1.875rem;
            font-weight: bold;
            line-height: 41px;
            border-bottom: 1px solid #d2cbcb;
            padding-bottom: 21px;
          }
          p::after {
            width: 38%;
            position: absolute;
            bottom: 0;
            left: 0;
            height: 3px;
            background-color: #008081;
            content: '';
          }
          h3 {
            color: #07844f;
            font-weight: bold;
            font-size: 1.875rem;
            text-transform: uppercase;
          }
          h4 {
            color: #000000;
            font-weight: bold;
            font-size: 1.875rem;
            text-transform: uppercase;
            .questionTitle {
              padding: 5px;
              border: 1px solid;
              margin-right: 10px;
              height: 45px;
              background-color: #019949;
              color: #ffffff;
              border-radius: 5px;
            }
            .answerTitle {
              padding: 5px;
              margin-right: 10px;
              height: 45px;
              min-width: 50px;
              background-color: #cfe077;
              color: #019949;
              border-radius: 5px;
              text-align: center;
            }
          }
          h5 {
            color: #07844f;
            font-weight: bold;
            font-size: 16px;
            // text-transform: uppercase;
          }
          .answerContent {
            display: flex;
          }
          .box-qa {
            display: flex;
          }
          .contentAsw {
            margin-top: 10px;
          }
          .answer {
            font-weight: normal;
            font-size: 16px;
            text-transform: none;
            letter-spacing: 1px;
            line-height: 20px;

          }
          .question {
            text-transform: none;
          }
         
          
        }
        .delivery-policy-screen__text {
          height: 66px;
          margin-top: 25px;
          display: flex;
          align-items: center;
          background: linear-gradient(89.98deg, #20abad 39.37%, rgba(32, 171, 173, 0) 97.41%);
          padding-left: 30px;
          p {
            color: #ffffff;
            font-size: 1.25rem;
            font-weight: bold;
          }
        }
        .delivery-policy-screen__standard-delivery {
          margin-top: 30px;
          .delivery-policy-screen__standard-delivery__top {
            display: flex;
            margin-bottom: 32px;
            .delivery-policy-screen__standard-delivery__top__left {
              width: 50%;
              .delivery-policy-screen__standard-delivery__top__left__condition {
                > p {
                  color: #000000;
                  font-size: 1.25rem;
                  font-weight: bold;
                  margin-bottom: 32px;
                }
                p {
                  word-break: break-word;
                  font-size: 1rem;
                  color: #000000;
                }
                > div {
                  margin-bottom: 40px;
                  p {
                    line-height: 22px;
                  }
                }
                img {
                  margin-right: 45px;
                }
              }
            }
            .delivery-policy-screen__standard-delivery__top__right {
              width: 50%;
              display: flex;
              img {
                width: 95%;
                height: 528px;
                margin-left: auto;
              }
            }
          }
          .delivery-policy-screen__standard-delivery__detail {
            display: flex;
            position: relative;
            width: 100%;
            margin-bottom: 40px;
            .delivery-policy-screen__standard-delivery__detail__line {
              width: 80%;
              position: absolute;
              height: 3px;
              background-color: #07844f;
              top: 20px;
            }
            .delivery-policy-screen__standard-delivery__detail__item {
              display: flex;
              width: 25%;
              flex-direction: column;
              padding-right: 3%;
              > img {
                width: 50px;
                height: 46px;
                z-index: 1;
              }
              .color-red {
                color: #ec1c24;
              }
              .delivery-policy-screen__standard-delivery__detail__item__title {
                margin: 11px 0;
                color: #07844f;
                font-size: 1rem;
                font-weight: bold;
              }
              .delivery-policy-screen__standard-delivery__detail__item__title__condition {
                display: flex;
                p {
                  font-weight: bold;
                  margin-left: 10px;
                  line-height: 22px;
                }
                img {
                  width: 21px;
                  height: 20px;
                }
              }
            }
          }
          .delivery-policy-screen__standard-delivery__box {
            display: flex;
            .delivery-policy-screen__standard-delivery__box__item {
              flex-grow: 1;
              background-color: #ffffff;
              box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.05);
              border-radius: 10px;
              padding: 1% 3% 0 28px;
              height: 268px;
              &:not(:last-child) {
                margin-right: 36px;
              }
              p {
                line-height: 22px;
              }
              .delivery-policy-screen__standard-delivery__box__item__top {
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                img {
                  margin-right: 10px;
                }
                p {
                  font-weight: bold;
                  span {
                    color: #333333;
                    font-size: 1.875rem;
                  }
                }
              }
            }
          }
        }
        .delivery-policy-screen__fast-delivery {
          margin-top: 30px;
          .delivery-policy-screen__fast-delivery__content {
            display: flex;
            margin-top: 40px;
            .delivery-policy-screen__fast-delivery__content__left {
              width: 50%;
              img {
                width: 100%;
              }
            }
            .delivery-policy-screen__fast-delivery__content__right {
              padding-left: 25px;
              width: 50%;
              p {
                line-height: 22px;
              }
              .color-red {
                color: #ec1c24;
              }

              .delivery-policy-screen__fast-delivery__content__right__detail {
                display: flex;
                position: relative;
                width: 100%;
                margin-top: 40px;
                .delivery-policy-screen__fast-delivery__content__right__detail__line {
                  width: 70%;
                  position: absolute;
                  height: 3px;
                  background-color: #07844f;
                  top: 20px;
                }
                .delivery-policy-screen__fast-delivery__content__right__detail__item {
                  display: flex;
                  width: 33.3333333333%;
                  flex-direction: column;
                  padding-right: 3%;
                  > img {
                    width: 50px;
                    height: 46px;
                    z-index: 1;
                  }
                  .color-red {
                    color: #ec1c24;
                  }
                  .delivery-policy-screen__fast-delivery__content__right__detail__item__title {
                    margin: 11px 0;
                    color: #07844f;
                    font-size: 1rem;
                    font-weight: bold;
                  }
                  .delivery-policy-screen__fast-delivery__content__right__detail__item__title__condition {
                    display: flex;
                    p {
                      font-weight: bold;
                      margin-left: 10px;
                      line-height: 22px;
                    }
                    span {
                      font-weight: normal;
                    }
                    img {
                      width: 21px;
                      height: 20px;
                    }
                  }
                }
              }
            }
          }
        }
        .delivery-policy-screen__payment {
          display: flex;
          margin-top: 30px;
          width: 100%;
          .delivery-policy-screen__payment__left {
            width: 50%;
            > p {
              color: #000000;
              font-size: 1rem;
              word-break: break-word;
              line-height: 22px;
              span {
                color: #20abad;
                font-weight: bold;
              }
            }
          }
          .delivery-policy-screen__payment__right {
            width: 50%;
            .delivery-policy-screen__payment__right__item {
              align-items: center;
              display: flex;
            }
          }
        }
      }
      .return-policy-screen {
        width: 100%;
        .return-policy-screen__title {
          position: relative;
          p {
            font-size: 1.875rem;
            font-weight: bold;
            line-height: 41px;
            border-bottom: 1px solid #d2cbcb;
            padding-bottom: 21px;
          }
          p::after {
            width: 38%;
            position: absolute;
            bottom: 0;
            left: 0;
            height: 3px;
            background-color: #008081;
            content: '';
          }
        }
        .return-policy-screen__condition {
          margin-top: 34px;
          display: flex;
          .return-policy-screen__condition__left {
            width: 40%;
            > p {
              line-height: 22px;
            }
          }
          .return-policy-screen__condition__right {
            width: 60%;
            display: flex;
            flex-wrap: wrap;
            padding-left: 5%;
            .return-policy-screen__condition__right__item {
              width: 33.3333333333%;
              margin-bottom: 20px;
              padding-right: 10%;
              .return-policy-screen__condition__right__item__img {
                height: 67px;
                margin-bottom: 10px;
              }
            }
          }
        }
        .return-policy-screen__product-condition {
          background-color: #20abad;
          border-radius: 5px;
          padding: 26px 49px 0 36px;
          height: 296px;
          color: #ffffff;
          font-size: 1rem;
          margin-bottom: 40px;
          .return-policy-screen__product-condition__title {
            font-size: 1.25rem;
            text-transform: uppercase;
            font-weight: bold;
          }
          .return-policy-screen__product-condition__item {
            display: flex;
            margin: 13px 0 19px 0;
            img {
              width: 21px;
              height: 20px;
              margin-right: 19px;
            }
            p {
              line-height: 22px;
            }
          }
        }
        .return-policy-screen__no-return-policy-apply {
          margin-bottom: 60px;
          .return-policy-screen__no-return-policy-apply__list {
            margin-top: 40px;
            display: flex;
            .return-policy-screen__no-return-policy-apply__list__item {
              width: 20%;
              background: #ffffff;
              box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.05);
              border-radius: 10px;
              padding: 50px 20px 20px 10px;
              color: #000000;
              line-height: 22px;
              .return-policy-screen__no-return-policy-apply__list__item__img {
                height: 66px;
                margin-bottom: 24px;
              }
              &:not(:last-child) {
                margin-right: 18px;
              }
            }
          }
        }
        .return-policy-screen__return-method__list {
          display: flex;
          flex-wrap: wrap;
          margin-top: 24px;
          color: #000000;
          font-size: 1rem;
          .return-policy-screen__return-method__list__item {
            width: 50%;
            align-items: center;
            margin-bottom: 40px;
            p {
              line-height: 22px;
              margin-bottom: 40px;
              display: flex;
              align-items: center;
            }
            span {
              padding-right: 22px;
              color: #333333;
              font-size: 1.875rem;
              font-weight: bold;
            }
          }
        }
        .return-policy-screen__refund-and-time {
          display: flex;
          > p {
            line-height: 22px;
            color: #000000;
          }
          .return-policy-screen__refund {
            flex: 1 1;
            padding-right: 4%;
          }
          .return-policy-screen__refund__time {
            flex: 1 1;
            > p {
              line-height: 22px;
              color: #000000;
              span {
                color: #20abad;
                font-weight: 400;
              }
            }
          }
        }
      }
      .privacy-policy-screen {
        .privacy-policy-screen__title {
          position: relative;
          p {
            font-size: 1.875rem;
            font-weight: bold;
            line-height: 41px;
            border-bottom: 1px solid #d2cbcb;
            padding-bottom: 21px;
          }
          p::after {
            width: 38%;
            position: absolute;
            bottom: 0;
            left: 0;
            height: 3px;
            background-color: #008081;
            content: '';
          }
        }
        .privacy-policy-screen__content__title {
          margin-top: 44px;
          p {
            color: #333333;
            font-weight: bold;
            font-size: 1.875rem;
            text-transform: uppercase;
          }
        }
        .privacy-policy-screen__content__part {
          font-size: 1.25rem;
          color: #333333;
          font-weight: bold;
        }
        .privacy-policy-screen__content__description {
          line-height: 22px;
          color: #000000;
          font-size: 1rem;
          span {
            color: #20abad;
            font-weight: bold;
          }
        }
      }
      .terms-of-service-screen {
        width: 100%;
        .terms-of-service-screen__title {
          position: relative;
          p {
            font-size: 1.875rem;
            font-weight: bold;
            line-height: 41px;
            border-bottom: 1px solid #d2cbcb;
            padding-bottom: 21px;
          }
          p::after {
            width: 38%;
            position: absolute;
            bottom: 0;
            left: 0;
            height: 3px;
            background-color: #008081;
            content: '';
          }
        }
        .terms-of-service-screen__content {
          .terms-of-service-screen__content__title {
            margin-top: 44px;
            p {
              color: #333333;
              font-weight: bold;
              font-size: 1.875rem;
              text-transform: uppercase;
            }
            h3 {
              color: #07844f;
              font-weight: bold;
              font-size: 1.875rem;
              text-transform: uppercase;
            }
          }
          .terms-of-service-screen__content__define {
            display: flex;
            .terms-of-service-screen__content__define__item {
              margin-right: 28px;
              padding: 23px 69px 22px 34px;
              background-color: #20abad;
              border-radius: 5px;
              color: #ffffff;
              p {
                line-height: 22px;
                font-size: 1rem;
              }
            }
          }
          .terms-of-service-screen__content__part {
            font-size: 1.25rem;
            color: #07844f;
            font-weight: bold;
          }
          .terms-of-service-screen__content__description {
            line-height: 22px;
            color: #000000;
            font-size: 1rem;
            font-weight: bold;
            span {
              color: #20abad;
              font-weight: bold;
            }
            p {
              font-weight: bold;
            }

            ul {
              color: #07844f;

              li {
                font-weight: bold;
                list-style-type: disc;
                margin-left: 20px;
                

                span {
                  color: #000000;
                }
              }
            }
          }
          .form_contact {
            .input-text {
              background: #f4f4f4;
              border: none;
              outline: none !important;
            }
            .required {
              padding: 3px;
              margin-right: 15px;
              border-radius: 5px;
              // background-color: rgba(236, 28, 36, 1);
              color: rgba(236, 28, 36, 1);
              font-weight: normal;
              font-size: 20px;
              padding-top: 10px;
            }
            label {
              font-weight: bold;
            }
            .input-check{
              width: 35px;
              height: 35px;
            }
            .form-check-label {
              margin: 12px;
            }
            .clause p {
              line-height: 25px;
            }

            button {
                font-weight: 600;
                font-size: 16px;
                line-height: 22px;
                background: #07844f;
                border-radius: 5px;
                padding: 10px 40px;
                width: 250px;
                text-align: center;
                color: #ffffff;
                border: none;
            }
          }
        }
      }
      .ordering-guide-screen {
        width: 100%;
        .ordering-guide-screen__title {
          position: relative;
          p {
            font-size: 1.875rem;
            font-weight: bold;
            line-height: 41px;
            border-bottom: 1px solid #d2cbcb;
            padding-bottom: 21px;
          }
          p::after {
            width: 38%;
            position: absolute;
            bottom: 0;
            left: 0;
            height: 3px;
            background-color: #008081;
            content: '';
          }
        }
        .ordering-guide-screen__content {
          margin-top: 33px;
          color: #000000;
          .ordering-guide-screen__content__red {
            color: #ec1c24;
            font-size: 14px;
          }
          .ordering-guide-screen__content__step {
            font-size: 1rem;
            color: #000000;
            display: flex;
            img {
              margin-right: 10px;
            }
          }
          .ordering-guide-screen__content--italic--blue {
            font-style: italic;
            color: #2f80ed;
          }
          .ordering-guide-screen__content__text__blue {
            color: #20abad;
            font-weight: bold;
          }
          .ordering-guide-screen__content__login-register {
            display: flex;
            position: relative;
            .ordering-guide-screen__content__login-register__left {
              width: 50%;
              .ordering-guide-screen__content__login-register__left__description {
                p {
                  font-size: 1rem;
                  line-height: 22px;
                }
              }
              z-index: 9;
            }
            .ordering-guide-screen__content__login-register__right {
              position: absolute;
              right: 0;
              top: 65px;
            }
          }
          .ordering-guide-screen__content__ordering-guide {
            margin-top: 30px;
            .ordering-guide-screen__content__ordering-guide__description {
              p {
                line-height: 22px;
                word-break: break-word;
              }
              .ordering-guide-screen__content__ordering-guide__description__step {
                display: flex;
                margin-bottom: 55px;
                .ordering-guide-screen__content__ordering-guide__description__text {
                  width: 50%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  flex-direction: column;
                }
                .ordering-guide-screen__content__ordering-guide__description__img {
                  width: 50%;
                  display: flex;
                  align-items: center;
                  img {
                    width: 90%;
                  }
                }
              }
            }
          }
          .ordering-guide-screen__content__purchase-form {
            display: flex;
            flex-wrap: wrap;
            .support-screen__item__title {
              width: 100%;
              margin-bottom: 20px;
            }
            .ordering-guide-screen__content__purchase-form__item {
              width: 49%;
              background-color: #20abad;
              border-radius: 30px;
              color: #ffffff;
              line-height: 22px;
              padding: 27px 31px 38px 40px;
              .ordering-guide-screen__content__purchase-form__item__title {
                text-transform: uppercase;
                font-weight: bold;
              }
              &:not(:last-child) {
                margin-right: 1%;
              }
            }
          }
          .ordering-guide-screen__content__order-success {
            display: flex;
            margin-top: 30px;
            .ordering-guide-screen__content__order-success__left {
              width: 50%;
              padding-right: 50px;
              .ordering-guide-screen__content__order-success__left__content {
                line-height: 22px;
              }
            }
            .ordering-guide-screen__content__order-success__right {
              width: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
          .ordering-guide-screen__content__invoice {
            margin-top: 30px;
            .support-screen__item__title {
              width: 100%;
              margin-bottom: 20px;
            }
            .ordering-guide-screen__content__invoice__item {
              display: flex;
              .ordering-guide-screen__content__invoice__left {
                width: 25%;
                padding-right: 50px;
                .ordering-guide-screen__content__invoice__left__content {
                  line-height: 22px;
                  .ordering-guide-screen__content__invoice__left__content__item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    p {
                      text-align: center;
                      color: #000000;
                      font-weight: bold;
                      text-transform: uppercase;
                    }
                  }
                }
              }
              .ordering-guide-screen__content__invoice__right {
                width: 75%;
                display: flex;
                flex-direction: column;
                .ordering-guide-screen__content__invoice__right__step {
                  display: flex;
                  p {
                    line-height: 22px;
                  }
                  .ordering-guide-screen__content__invoice__right__step__img {
                    margin-right: 10px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
a {
  text-decoration: none;
}

.mt-14 {
  margin-top: 14px;
}
.mt-20 {
  margin-top: 20px;
}
.fs-10 {
  font-size: 0.625rem;
}
.mt-10 {
  margin-top: 10px;
}
.mb-40 {
  margin-bottom: 154px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mr-56 {
  margin-right: 56px;
}
.mr-77 {
  margin-right: 77px;
}
.mr-13 {
  margin-right: 13px;
}
.mr-15 {
  margin-right: 15px;
}
.pr-50 {
  padding-right: 50px !important;
}
.pl-10-percent {
  padding-left: 10%;
}
.pl-12-percent {
  padding-left: 12%;
}
.pl-8-percent {
  padding-left: 8%;
}
.mt--45 {
margin-top: -45px;
}

//set width
.w-43 {
  width: 43px;
}
.w-46 {
  width: 46px;
}
.w-49 {
  width: 49px;
}
.w-52 {
  width: 52px;
}
.w-56 {
  width: 56px;
}
.w-59 {
  width: 59px;
}
.w-61 {
  width: 61px;
}
.w-62 {
  width: 62px;
}
.w-63 {
  width: 63px;
}
.w-69 {
  width: 69px;
}
.w-76 {
  width: 76px;
}

//set height
.h-47 {
  height: 47px;
}
.h-50 {
  height: 50px;
}
.h-51 {
  height: 51px;
}
.h-54 {
  height: 54px;
}
.h-57 {
  height: 57px;
}
.h-52 {
  height: 52px;
}
.h-62 {
  height: 62px;
}
.h-66 {
  height: 66px;
}
.h-67 {
  height: 67px;
}
.font-weight-bold {
  font-weight: bold;
}
.font-style-italic {
  font-style: italic;
}

.lh-22 {
  line-height: 22px;
}



@media screen and (max-width: 1366px) {
  .ordering-guide-screen__content__login-register {
    .ordering-guide-screen__content__login-register__right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 100%;
      img {
        width: 50%;
      }
    }
  }
}
