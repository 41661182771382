@import 'var';

.footer {
  border-top: 1px solid $baseColor;
  // padding-bottom: 80px;
  background: $whiteColor;
  font-family: 'Archivo', sans-serif;
  a {
    color: #000000;
  }
  .footer-content {
    padding: 10px 25px;
  }
}

.other {
  margin-top: 37px;
}

.video {
  display: flex;
  .box-video {
    margin-left: 10px;
    border: 1px solid hwb(0 80% 15%);
    border-radius: 5px;
  }
  video {
    width: 250px;
    height: 145px;
  }
}

.footer-top__right {
  display: flex;
  height: 20px;
  white-space: nowrap;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 15px;
  color: $whiteColor;
  padding: 3px 15px;
  margin-bottom: 10px;
  border-right: 1px solid $baseColor;
  cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  
    a,
    p {
      color: #000000;
      font-size: 16px;
      display: -moz-box;
      display: flex;
      -moz-box-align: center;
      align-items: center;
      line-height: 17px;
      // font-weight: 600;
  
      img {
        height: 17px;
        margin-right: 10px;
      }
    }
}

.footer-top__right:last-child {
  border-right: none;
}

.footer-content__list-link {
  justify-content: space-between;
  padding: 50px 0;

  .footer-list-title {
    font-size: 20px;
    line-height: 27px;
    text-transform: uppercase;
    color: $blackColor;

    &--bold {
      font-weight: 600;
      margin-bottom: 40px;
    }

    &--normal {
      font-weight: normal;
      margin-bottom: 30px;
      margin-top: 30px;
    }

    @media screen and (max-width: 1611px) {
      font-size: 18px;
      line-height: 25px;
    }
  }

  .footer-logo {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    img:first-child {
      margin-right: 30px;
    }

    @media screen and (min-width: 1200px) and (max-width: 1611px) {
      img:first-child {
        margin-right: 20px;
      }

      &--responsive {
        img {
          width: 160px;
        }
      }
    }

    @media screen and (max-width: 1199px) {
      img:first-child {
        margin-right: 15px;
      }

      &--responsive {
        img {
          width: 140px;
        }
      }
    }
  }

  .footer-video {
    display: flex;
    align-items: center;

    img:first-child {
      margin-right: 30px;
      margin-bottom: 30px;
    }

    .video-wrap {
      margin-bottom: 30px;
    }

    @media screen and (max-width: 1400px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .footer-list {
    .footer-list__link a {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 40px;
      text-align: justify;
      color: $blackColor;
      cursor: pointer;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }

      @media screen and (max-width: 1611px) {
        font-size: 14px;
        line-height: 36px;
      }
    }
  }
}

.footer-content__info {
  // border-top: 1px solid #d2cbcb;
  padding: 10px 0;

  .content-info {
    .logo-wrap {
      display: flex;
      align-items: center;
      display: inline;
      img {
        margin-left: -50px;
      }

      h4 {
        font-size: 14px;
        line-height: 30px;
        text-align: justify;
        color: $baseColorStrong;
        // font-weight: bold;
        margin-left: -18px;
      }
      
    }
    

    .footer-item-info {
      display: flex;
      align-items: center;
      margin: 10px 0;

      .icon-info {
        display: flex;
        justify-content: flex-start;
        width: 45px;
      }

      p {
        flex: 1;
        font-size: 16px;
        line-height: 20px;
        color: $blackColor;

        span {
          display: block;
        }
      }
    }

    .footer-item-info-company {
        align-items: center;
        margin: 15px 0;
    }

  }

  .content-copryright {
  }

  .content-copyright__logo {
    p {
      font-size: 16px;
      line-height: 22px;
      color: $blackColor;
      margin-bottom: 15px;
    }
  }

  .content-copyright__text {
    h4 {
      font-size: 14px;
      line-height: 30px;
      text-align: justify;
      color: $baseColorStrong;
      // font-weight: bold;
      margin-left: 30px;
    }

    p {
      font-size: 12px;
      line-height: 30px;
      text-align: justify;
      color: $blackColor;
    }
  }

  .content-copyright__logo__item {
    display: flex;
    align-items: center;

    img {
      max-width: 100%;
    }
  }

  @media screen and (max-width: 1365px) {
    .content-copyright__logo p {
      font-size: 14px;
      line-height: 19px;
      margin-bottom: 10px;
    }

    .content-info {
      .logo-wrap img {
        max-width: 100%;
      }

      .footer-item-info p {
        font-size: 14px;
        line-height: 19px;
      }
    }
  }

  
}
