@import 'var';

.page-product__breadcrumb {
  font-size: 16px;
  line-height: 20px;
  color: $baseColorLink;
  padding-bottom: 25px;

  a {
    cursor: pointer;
    color: $baseColorLink;
    text-decoration: none;

    &:hover {
      text-decoration: underline !important;
    }
  }
}

.page-product__content {
  .product-content {
    display: flex;
    margin: 0 -10px;
  }
}

.product-content__block-image {
  padding: 0 10px;

  .product-content__block-image-wrap {
    width: 450px;
  }

  .image-view {
    width: 440px;
    height: 440px;
    border: 1px solid #adadad;
    box-sizing: border-box;
    border-radius: 5px;
    overflow: hidden;
    margin: 0 5px 15px 5px;

    img {
      width: 100%;
      max-width: 100%;
      object-fit: contain;
      max-height: 100%;
    }
  }

  .image-preview {
    padding: 0 5px;
    width: 90px;

    .image-preview__wrap {
      width: 80px;
      height: 80px;
      border: 1px solid $whiteColor;
      box-sizing: border-box;
      border-radius: 5px;
      overflow: hidden;
      cursor: pointer;

      &.active {
        border-color: $baseColorLight;
      }
    }

    img {
      width: 100%;
      max-width: 100%;
      object-fit: contain;
      max-height: 100%;
    }
  }

  .slider-view {
    position: relative;
  }

  .slider-view__btn {
    position: absolute;
    z-index: 5;
    background: rgba(196, 196, 196, 0.7);
    padding: 10px 8px;
    cursor: pointer;
    border: none;

    i {
      color: $whiteColor;
      font-size: 20px;
    }

    &--next {
      top: 20px;
      right: 5px;
      border-radius: 10px 0 0 10px;
    }

    &--prev {
      top: 20px;
      left: 5px;
      border-radius: 0 10px 10px 0;
    }
  }

  .share-view {
    display: flex;
    align-items: center;
    padding: 15px 0;

    p {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #767474;
    }

    a {
      cursor: pointer;
      margin-left: 20px;
      transition: 0.5s all;

      &:hover {
        transform: scale(1.2);
      }
    }
  }

  .favourite-view {
    display: flex;
    align-items: center;
    position: relative;

    &__add-category-drop-down {
      position: absolute;
      background: #ffffff;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      padding: 15px 15px;
      top: 0px;
      left: 0;
      right: 0;
      border: 1px solid rgba(0, 0, 0, 0.1);
      display: none;

      &.show {
        display: block;
      }

      h4 {
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        color: #000000;
        width: 100%;
        border-bottom: 1px solid #bdbdbd;
        padding-bottom: 10px;
        margin-bottom: 15px;
      }

      &__search-box {
        box-sizing: border-box;
        background: #e0e0e0;
        border-radius: 50px;
        display: flex;
        align-items: center;
        overflow: hidden;
        padding: 5px 5px 5px 20px;
        margin-bottom: 10px;

        .input-box {
          border: none;
          flex: 1;
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          color: #767474;
          background: #e0e0e0;
        }

        .input-box:focus {
          outline: none;
        }

        .icon-search {
          width: 35px;
          height: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: $orangeColor;
          border-radius: 25px;
          cursor: pointer;

          img {
            width: 20px;
          }
        }
      }

      &__list-item {
        li {
          display: flex;
          align-items: center;
          padding: 15px 0;
          border-bottom: 1px solid #bdbdbd;

          &:last-child {
            border: none;
          }
        }

        p {
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          color: #000000;
          flex: 1;
          padding: 0 20px;
        }

        i {
          color: $whiteColor;
          font-size: 11px;
        }

        button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 30px;
          height: 30px;
          background: #20abad;
          border-radius: 20px;
          border: none;

          &:focus {
            outline: none;
          }
        }
      }

      &__btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 10px;

        &__btn-add {
          border: 1px solid #000000;
          box-sizing: border-box;
          border-radius: 30px;
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          color: #000000;
          background: transparent;
          padding: 12px 24px;
        }

        &__btn-done {
          border: none;
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          background: #f15b2e;
          border-radius: 30px;
          color: #ffffff;
          padding: 12px 28px;
        }
      }
    }

    &__section {
      display: flex;
      cursor: pointer;
    }
  }

  .favourite-view__title {
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    padding: 0 10px;
  }

  .favourite-view__add-category {
    flex: 1;
    text-align: right;
    font-size: 16px;
    line-height: 22px;
    color: #000000;

    span {
      font-size: 14px;
      line-height: 19px;
      text-decoration-line: underline;
      color: $baseColorStrong;
      padding: 0 8px;
      cursor: pointer;
    }
  }

  .favourite-view__add-category-drop-down {
    z-index: 2;

    .favourite-view__add-category-drop-down__list-item {
      max-height: 300px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 10px;
      }
    }
  }

  @media screen and (min-width: 1250px) and (max-width: 1611px) {
    .product-content__block-image-wrap {
      width: 380px;
    }

    .image-view {
      width: 380px;
      height: 380px;
      border: 1px solid #adadad;
      box-sizing: border-box;
      border-radius: 5px;
      overflow: hidden;
      margin: 0 5px 15px 5px;

      img {
        width: 100%;
        max-width: 100%;
        object-fit: contain;
        max-height: 100%;
      }
    }

    .image-preview {
      padding: 0 5px;
      width: 80px;

      .image-preview__wrap {
        width: 70px;
        height: 70px;
        border: 1px solid $whiteColor;
        box-sizing: border-box;
        border-radius: 5px;
        overflow: hidden;
        cursor: pointer;

        &.active {
          border-color: $baseColorLight;
        }
      }

      img {
        width: 100%;
        max-width: 100%;
        object-fit: contain;
        max-height: 100%;
      }
    }
  }

  @media screen and (max-width: 1249px) {
    .product-content__block-image-wrap {
      width: 300px;
    }

    .image-view {
      width: 290px;
      height: 290px;
      border: 1px solid #adadad;
      box-sizing: border-box;
      border-radius: 5px;
      overflow: hidden;
      margin: 0 5px 15px 5px;

      img {
        width: 100%;
        max-width: 100%;
        object-fit: contain;
        max-height: 100%;
      }
    }

    .image-preview {
      padding: 0 5px;
      width: 60px;

      .image-preview__wrap {
        width: 50px;
        height: 50px;
        border: 1px solid $whiteColor;
        box-sizing: border-box;
        border-radius: 5px;
        overflow: hidden;
        cursor: pointer;

        &.active {
          border-color: $baseColorLight;
        }
      }

      img {
        width: 100%;
        max-width: 100%;
        object-fit: contain;
        max-height: 100%;
      }
    }

    .slider-view__btn {
      padding: 5px 10px;

      i {
        font-size: 12px;
      }

      &--next {
        top: 10px;
      }

      &--prev {
        top: 10px;
      }
    }
  }
}

.product-content__block-info {
  padding: 0 10px;
  flex: 1;

  .product-full-name {
    font-weight: bold;
    font-size: 30px;
    line-height: 41px;
    color: #000000;
  }

  .product-content__block-info__name {
    padding-bottom: 15px;
    border-bottom: 1px solid #828282;
  }

  .rating-view {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .rating-view__sold {
    font-size: 14px;
    line-height: 19px;
    color: #000000;
  }

  .rating-view__star {
    display: flex;
    align-items: center;

    p {
      font-size: 14px;
      line-height: 19px;
      color: $blackColor;
    }

    .star-list-view {
      padding: 0 15px;
    }
  }
}

.product-content__block-info__price {
  padding: 10px 0;
  border-bottom: 1px solid #828282;

  .block-discount {
    background: linear-gradient(89.93deg, #fb800f 0.03%, #f0592d 99.92%);
    display: flex;
    color: transparent;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    padding: 5px 20px;
  }

  .price-discount {
    font-weight: bold;
    font-size: 30px;
    line-height: 41px;
    color: $whiteColor;
  }

  .price-origin {
    font-size: 14px;
    line-height: 19px;
    text-decoration-line: line-through;
    color: $whiteColor;
  }

  .text-discount {
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    color: $whiteColor;
    padding-left: 5px;
  }

  .block-discount__time {
    display: flex;
    flex-direction: column;
    align-items: center;

    .text-title {
      font-size: 16px;
      line-height: 22px;
      color: $whiteColor;
      padding-bottom: 5px;
    }
  }

  .time-wrap {
    display: flex;
    align-items: center;

    p {
      font-size: 16px;
      line-height: 16px;
      color: $whiteColor;
      padding: 0 10px;

      &.countdown-time {
        padding: 5px 5px;
        background: #434343;
        border-radius: 5px;
      }
    }
  }

  .block-description {
    display: flex;
    align-items: center;
    padding-top: 10px;

    .icon-wrap {
      width: 60px;
      display: flex;
      justify-content: center;
    }

    &--normal {
      p {
        font-size: 14px;
        line-height: 19px;
        color: $blackColor;
      }

      span {
        font-weight: 700;
      }
    }
  }

  .block-description--flex {
    .text-wrap__content {
      font-weight: bold;
      font-size: 14px;
      line-height: 19px;
      color: $orangeColor2;

      span {
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: $blackColor;
        padding-left: 40px;
      }
    }

    .text-wrap__description {
      font-size: 10px;
      line-height: 14px;
      color: #767474;
    }
  }
}

.product-content__block-info__type {
  padding: 10px 0;
  border-bottom: 1px solid #828282;

  .title-picker {
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    color: #000000;
    margin-bottom: 10px;
  }

  .block-picker {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .block-picker__item--normal {
    border: 1px solid #767474;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 7px 15px;
    cursor: pointer;
    margin-right: 12px;
    margin-bottom: 10px;

    .text-name {
      font-size: 14px;
      line-height: 19px;
      color: #767474;
    }

    .text-price {
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      color: #767474;
    }

    .voucher-description {
      height: 19px;
    }

    &.active {
      border-color: $baseColorLight;
      background-color: $baseColorBtn;

      .text-name,
      .text-price {
        color: $whiteColor;
      }
    }
  }

  .block-picker__item--voucher {
    background-image: url('../images/DetailProductScreen/bg_voucher.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
    cursor: pointer;
    margin-right: 12px;
    padding: 10px 10px 10px 8px;
    margin-bottom: 10px;
    display: inline-block;

    p {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #767474;
    }

    .voucher-description {
      height: 19px;
    }

    span {
      font-size: 12px;
      line-height: 16px;
      color: #767474;
    }

    &.active {
      background-image: url('../images/DetailProductScreen/bg_voucher_active.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: center center;

      p,
      span {
        color: $baseColorStrong;
      }
    }
  }
}

.product-content__block-info__description {
  padding: 10px 0;

  .description-title {
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    color: $blackColor;
    margin-bottom: 10px;
  }

  .table-row {
    .table-row__tile {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #767474;
      width: 20%;
    }

    .table-row__content {
      font-size: 16px;
      line-height: 22px;
      color: #000000;
      padding-bottom: 15px;
      text-align: justify;
      padding-left: 15px;

      &--link {
        text-decoration-line: underline;
        cursor: pointer;
        color: $baseColorStrong;
      }

      &--bordered {
        div {
          padding: 3px 20px;
          border: 1px solid $blackColor;
          border-radius: 20px;
          display: inline-flex;
        }
      }
    }
  }
}

.product-content__block-buying {
  padding: 0 10px;
  width: 385px;

  .block-buying-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border: 1px solid #767474;
    border-radius: 25px;
    cursor: pointer;

    span {
      width: 14px;
      height: 14px;
      border-radius: 14px;
    }

    &.active {
      border: 1px solid $orangeColor;

      span {
        background: $orangeColor;
      }
    }
  }

  .block-buying {
    padding-left: 10px;
    padding-right: 15px;
    flex: 1;
  }

  .block-buying__title {
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    color: #767474;
  }

  .block-buying__price {
    display: flex;
    align-items: center;
    margin: 10px 0;

    p {
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      color: #767474;
    }
    span {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #767474;
      margin-left: 10px;
      border: 1.5px solid #767474;
      border-radius: 5px;
      padding: 1px 3px;
    }
  }

  .block-buying__quantity {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;

    p {
      font-size: 14px;
      line-height: 19px;
      color: $blackColor;
    }
  }

  .block-buying__btn {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .btn-buy {
      text-decoration: none;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: $orangeColor;
      border: 1px solid $orangeColor;
      border-radius: 30px;
      cursor: pointer;
      padding: 12px 20px;
      margin-right: 10px;
      background: $whiteColor;
    }

    .btn-add-cart {
      margin-left: 10px;
      text-decoration: none;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: $whiteColor;
      border: 1px solid $orangeColor;
      background: $orangeColor;
      border-radius: 30px;
      cursor: pointer;
      padding: 12px 20px;
    }

    .btn-add-cart,
    .btn-buy {
      transition: 0.5s all;

      &:hover {
        transform: scale(1.1);
      }
    }
  }
}

.product-content__block-buying__retail {
  padding: 15px 10px;
  background: #f7f7f7;
  border-radius: 5px;
  display: flex;
  border: 1px solid $whiteColor;

  &.active {
    background: $whiteColor;
    border: 1px solid $baseColorLight;

    .block-buying__title {
      color: $blackColor;
    }

    .block-buying__price {
      p {
        color: $baseColorStrong;
      }
      span {
        color: $orangeColor2;
        border: 1.5px solid $orangeColor2;
      }
    }

    .block-buying__promotion p {
      color: #fabb00;
    }
  }

  .block-buying__promotion {
    display: flex;
    align-items: center;

    img {
      width: 16px;
    }

    p {
      margin-left: 10px;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #767474;
    }
  }
}

.quantity-wrap {
  display: flex;
  align-items: center;
  background: $baseColorLight;
  border-radius: 20px;
  padding: 5px 5px;
  margin-left: 10px;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background: $whiteColor;
    cursor: pointer;
    border: none;

    &.disable {
      background: #e5e3e3;
    }
  }

  input {
    width: 40px;
    border: none;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: $whiteColor;
    background: $baseColorLight;
    text-align: center;

    &:focus {
      outline: none;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}

.product-content__block-buying__regular {
  padding: 15px 10px;
  background: #f7f7f7;
  border-radius: 5px;
  display: flex;
  margin-top: 15px;
  border: 1px solid $whiteColor;

  &.active {
    background: $whiteColor;
    border: 1px solid $baseColorLight;

    .block-buying__title {
      color: $blackColor;
    }

    .block-buying__price {
      p {
        color: $baseColorStrong;
      }
      span {
        color: $orangeColor2;
        border: 1.5px solid $orangeColor2;
      }
    }

    .block-buying__promotion p {
      color: $blackColor;
    }

    .block-buying__question p {
      color: $blackColor;
    }

    .block-buying__discount {
      span {
        &:hover {
          cursor: pointer;
        }
        &.active {
          background: $orangeColor2;
        }
      }
    }
  }

  .block-buying__discount {
    display: flex;
    align-items: center;
    margin-top: 5px;

    div {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-radius: 20px;
      overflow: hidden;
    }

    span {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #767474;
      width: 35px;
      text-align: center;
      padding: 1px 2px;
      background: #c4c4c4;
    }

    .active {
      background: #767474;
      color: $whiteColor;
    }
  }

  .block-buying__promotion {
    display: flex;
    align-items: center;
    margin-top: 5px;

    img {
      width: 16px;
    }

    p {
      margin-left: 10px;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #767474;
    }
  }

  .block-buying__question {
    display: flex;
    margin-top: 5px;
    align-items: flex-start;

    img {
      width: 20px;
    }

    p {
      margin-left: 6px;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #767474;
    }
  }

  .block-buying__period {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: 20px;

    .period-title {
      font-size: 14px;
      line-height: 19px;
      color: $blackColor;
      padding-top: 10px;
    }

    .time-title {
      font-weight: 300;
      font-size: 12px;
      line-height: 16px;
      padding-top: 10px;
      color: $blackColor;
    }

    .time-text {
      font-weight: bold;
      font-size: 14px;
      line-height: 19px;
      color: $blackColor;
      padding-top: 5px;

      span {
        font-size: 12px;
        line-height: 16px;
        color: #767474;
      }
    }
  }

  .period-select {
    background: rgba(32, 171, 173, 0.1);
    border: 1px solid $baseColorLight;
    box-sizing: border-box;
    border-radius: 5px;
    max-width: 170px;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: $baseColorLight;
    padding: 10px 5px;

    &:focus {
      outline: none;
    }

    option {
      font-weight: bold;
      font-size: 14px;
      line-height: 19px;
      color: $baseColorLight;
      background: #ffffff;
      padding: 10px 0;
    }

    option:not(:checked) {
      color: #000000;
      font-weight: 400;
    }
  }
}

.page-product__related-section {
  padding-top: 40px;

  .related-section-title {
    border-bottom: 1px solid #d2cbcb;
    position: relative;
  }

  .related-section-title__text {
    font-weight: bold;
    font-size: 25px;
    line-height: 34px;
    color: $blackColor;
    padding-bottom: 20px;
  }

  .related-section-title__underline {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    height: 3px;
    width: 450px;
    background: $baseColorStrong;
  }
}

.page-product__suggest-deal-section {
  padding-top: 40px;

  .suggest-deal-title h4 {
    font-weight: bold;
    font-size: 25px;
    line-height: 34px;
    color: $blackColor;
    margin-bottom: 20px;
  }

  .suggest-deal-content {
    display: flex;
    align-items: center;
  }

  .suggest-deal-content__icon {
    margin: 0 15px;

    @media screen and (max-width: 1199px) {
      width: 20px;
      margin: 0 10px;
    }
  }

  .suggest-deal-content__total-block {
    padding-left: 10px;
  }

  .suggest-deal-content__total-block__price {
    font-weight: 800;
    font-size: 20px;
    line-height: 27px;
    color: $baseColorStrong;
  }

  .suggest-deal-content__total-block__discount {
    font-size: 16px;
    line-height: 22px;
    color: $blackColor;
  }

  .suggest-deal-content__total-block__btn {
    background: $orangeColor;
    border-radius: 20px;
    display: flex;
    align-items: center;
    padding: 5px 20px;
    margin-top: 10px;
    cursor: pointer;
    border: none;
    
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      border-radius: 24px;
      border: 1px solid $whiteColor;
    }

    p {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: $whiteColor;
      padding-left: 10px;
    }

    @media screen and (max-width: 1199px) {
      padding: 5px 10px;

      p {
        font-size: 12px;
        padding-left: 5px;
      }

      div {
        width: 18px;
        height: 18px;
      }

      img {
        width: 12px;
      }
    }
  }
}

.page-product__detail-info-section {
  padding-top: 40px;

  .detail-info-section-title {
    border-bottom: 1px solid #d2cbcb;
    position: relative;
  }

  .detail-info-section-title__text {
    font-weight: bold;
    font-size: 25px;
    line-height: 34px;
    color: $blackColor;
    padding-bottom: 20px;
  }

  .detail-info-section-title__underline {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    height: 3px;
    width: 200px;
    background: $baseColorStrong;
  }

  .detail-info-section-content {
    padding-top: 25px;
    line-height: 1.5;

    .nav-link {
      background: #e5e5e5;
      border-radius: 5px 5px 0px 0px;
      font-size: 16px;
      line-height: 22px;
      color: $blackColor;
      min-width: 150px;
      margin-right: 2px;
      border: none;

      &:focus {
        outline: none;
      }

      &.active {
        background: $baseColorBtn;
        color: $whiteColor;
      }
    }

    h1, h2, h3 {
      font-size: 16px;
    }
  }
}

.page-product__review-section {
  padding-top: 40px;

  .review-section-title {
    border-bottom: 1px solid #d2cbcb;
    position: relative;
  }

  .review-section-title__text {
    font-weight: bold;
    font-size: 25px;
    line-height: 34px;
    color: $blackColor;
    padding-bottom: 20px;
  }

  .review-section-title__underline {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    height: 3px;
    width: 200px;
    background: $baseColorStrong;
  }

  .review-section-content {
    padding-top: 25px;
    display: flex;
    align-items: center;
  }

  .review-total-score {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px 20px;

    .review-total-score__average {
      font-weight: 600;
      font-size: 20px;
      line-height: 16px;
      letter-spacing: 0.1px;
      color: #181725;
      padding-bottom: 15px;
    }

    .review-total-score__count {
      font-size: 16px;
      line-height: 16px;
      letter-spacing: 0.1px;
      color: #7c7c7c;
      padding-top: 15px;
    }
  }

  .review-detail-score {
    padding: 0 20px;
    border-left: 1px solid #e2e2e2;
    flex: 1;
  }

  .review-detail-score__item {
    display: flex;
    align-items: center;
    padding: 2px 0;

    p {
      font-size: 16px;
      line-height: 16px;
      color: #181725;
    }

    .percent-display-row {
      width: 250px;
      height: 6px;
      background: #e0e0e0;
      border-radius: 2px;
      margin: 0 20px;

      span {
        display: block;
        height: 6px;
        background: #bdbdbd;
        border-radius: 2px;
      }
    }
  }
}
.navbar-sticky {
  position: fixed;
  top: 146px;
  width: 100%;
  z-index: 99;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  background: #fff;
}
.z-index-none {
  z-index: 0 !important;
}
.tab-content {
  img {
    max-width: 100%;
    object-fit: contain;
    height: auto;
  }
  ul {
    list-style-type: disc;
    padding-left: 2rem;
    margin-bottom: 1rem;
  }
  figure {
    margin: 0 auto;
    text-align: center;
  }
}