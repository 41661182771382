@import 'var';

.bg-content {
  background-color: $lightBlue;
}
.p-45 {
  padding-left: 209px;
}

.branding-common-page2 {
  max-width: 1903px;
  padding: 0px;
  overflow: hidden;
  .page-product__breadcrumb {
    margin: 30px 146px;
  }
}
.wrap-container {
  width: 100% !important;
  
}
.container-brand {
  padding: 25px 15px 0px 15px;
}
.branding-common-page {
  flex: 1;

  img {
    max-width: 100%;
    object-fit: contain;
  }

  .brand-detail-screen_content__title {
    p {
      color: #333333;
      font-size: 1.875rem;
      font-weight: bold;
      text-transform: uppercase;
    }
    h4 {
      color: #b3322f;
      font-size: 1.875rem;
      font-weight: bold;
      text-transform: uppercase;
    }

    h5 {
      color: #333333;
      font-size: 1.5rem;
      font-weight: bold;
      // text-transform: uppercase;
    }

    h1 {
      color: #333333;
      font-size: 64px;
      font-weight: bold;
      text-align: center;
      background: -webkit-linear-gradient(90deg, #BB994F 0%, #F0E1B6 18.5%, #D6BD83 34%, #DCC68F 58%, #E3CF9C 77%, #D6BD83 96.5%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    h2 {
      color: #333333;
      font-size: 40px;
      font-weight: bold;
      background: -webkit-linear-gradient(90deg, #BB994F 0%, #F0E1B6 18.5%, #D6BD83 34%, #DCC68F 58%, #E3CF9C 77%, #D6BD83 96.5%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

   h3 {
    color: #333333;
      font-size: 100px;
      font-weight: bold; 
      text-align: center;
      background: -webkit-linear-gradient(90deg, #BB994F 0%, #F0E1B6 18.5%, #D6BD83 34%, #DCC68F 58%, #E3CF9C 77%, #D6BD83 96.5%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-top: 30px;
      text-transform: uppercase;
   }

  }

  .private-brand {
    width: 100%;
    background-color: #07844F;
    text-align: center;
    padding: 35px;
    span {
      font-weight: bold;
      font-size: 60px;
      color: #ffffff;
    }
  }

  .brand-detail-screen_content {
    .brand-intro {
      p {
        line-height: 22px;
        font-weight: bold;
      }
    }
    .box-text {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .box-text-product {
      display: flex;
      align-items: center;
      // justify-content: center;
    }
    .image-box-final {
      text-align: center;
    }
    .titleBrand {
      font-weight: bold;
      font-size: 40px;
    }
  }
  .brand-page-heading__text {
    font-weight: bold;
    font-style: italic;
  }

  // .line-up-box {
  //   background-image: url('../images/brandSelectDetail/lineup/bg-image-brand.png');
  //   padding: 0px 40px;

  //   .line-up-item {
  //     display: flex;
  //     align-items: center;

  //     .line-up-content {
  //       margin-left: 120px;
  //       h4 {
  //         color: #b3322f;
  //         font-size: 1.875rem;
  //         font-weight: bold;
  //         text-transform: uppercase;
  //       }
  //     }
  //   }
  //   .cl-black{
  //     color: #000000 !important;
  //   }
  // }

  .line-up-box { 
    p {
      font-weight: 700;
    }
    position: relative;
    padding: 150px 20px;
    overflow: hidden; /* Đảm bảo rằng phần tử pseudo không vượt ra ngoài hộp */
  
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url('../images/brandSelectDetail/lineup/BG.jpg');
      background-size: cover;
      background-position: center;
      filter: blur(20px); /* Độ mờ, điều chỉnh giá trị theo nhu cầu */
      z-index: -1; /* Đảm bảo rằng lớp phủ nằm phía sau nội dung */
    }
  
    .line-up-item {
      position: relative;
      z-index: 1; /* Nội dung nằm phía trên lớp phủ mờ */
      display: flex;
      align-items: center;
      .line-up-content {
        margin-left: 120px;
        h4 {
          color: #b3322f;
          font-size: 1.875rem;
          font-weight: bold;
          text-transform: uppercase;
        }
      }
    }
  
    .cl-black {
      color: #000000 !important;
    }
  }
  .fw-4 {
    font-weight: 400 !important;
  }
  .fw-7 {
    font-weight: 700 !important;
  }

  .box-image-line-up {
    display: flex;
    flex-wrap: wrap; /* Cho phép nội dung wrap xuống hàng mới nếu cần */
    justify-content: center; /* Căn giữa toàn bộ dòng ảnh theo chiều ngang */
    gap: 10px; /* Khoảng cách giữa các hình ảnh, điều chỉnh theo ý muốn */
  }
  
  .img-item {
    display: flex;
    justify-content: center; /* Căn giữa ảnh theo chiều ngang */
    align-items: center;     /* Căn giữa ảnh theo chiều dọc */
    width: 75%;            /* Đặt chiều rộng cố định cho từng item nếu cần */
    height: auto;           /* Đặt chiều cao cố định cho từng item nếu cần */
    background-color: #f5f5f5; /* Màu nền để thấy rõ vùng chứa ảnh */
  }
  .copyRight {
    position: relative;
    img {
      position: absolute;
    }
  }
  .image-resize {
    margin-left: 30px;
  }
}
