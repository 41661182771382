// $baseColor: #1daca6;
$whiteColor: #ffffff;
$orangeColor: #f0592d;
$orangeColor2: #fb800f;
$blackColor: #000000;
// $baseColorStrong: #008080;
// $baseColorLight: #20abad;
$lightBlue: #BAE8F0;
$border-color: #767474;

$baseColor: #019949;
$searchColorHome: #019949;
$baseColorLight: #019949;
$baseColorStrong: #000000;

$baseColorLink: rgb(0, 128, 128);
$baseColorBtn: #07844f