/* LoadingOverlay.css */
@import './var.scss';
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(58 58 58 / 13%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Đảm bảo nó ở trên cùng */
  i{
    font-size: 50px;
    color: #008080;
  }
}

.spinner {
  border: 8px solid rgba(0, 0, 0, 0.3);
  border-top: 8px solid #3498db; /* Màu của spinner */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
