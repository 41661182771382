.banner__category-product {
  display: flex;
  flex: 1;
  width: 25%;
  background-color: #ffffff;

  @media screen and (max-width: 1024px) {
    display: none;
  }
  .category-product__menu__ul {
    width: 100%;

    .category-product__menu__li {
      width: 100%;
      position: relative;
      border-bottom: 1px solid #e0e0e0;

      a {
        color: #000000;
        font-size: 14px;

        .category-product__item {
          // height: 43px;
          height: 55px;
          width: 100%;

          .category-product__icon {
            // width: 26px;
            // height: 24px;
            width: 35px;
            height: 35px;
            margin: 10px 17px;
            vertical-align: top;
          }

          p {
            display: inline-block;
            line-height: 55px;
          }

          .slick-arrow {
            float: right;
            margin-right: 5px;
          }
        }
      }

      &:hover {
        .category-product__menu__li__sub {
          display: inherit;
        }

        background-color: #d4f4f4;
      }

      .category-product__menu__li__sub {
        display: none;
        position: absolute;
        background-color: #f4f4f4;
        max-width: 907px;
        padding: 20px 40px 20px 12px;
        left: 100%;
        top: 0;
        z-index: 99;

        ul {
          max-width: 907px;
          display: flex;
          flex-direction: row;

          li {
            width: 128px;
            text-align: center;
            cursor: pointer;
            // height: 130px;
            box-sizing: border-box;
            margin-right: 15px;
            a {
              text-decoration: none;

              img {
                width: 48px;
                height: 48px;
                object-fit: contain;
                margin-bottom: 10px;
              }
            }
          }
        }
      }
    }
  }
}
.healthy-screen-sidebar {
  .banner__category-product {
    width: 100%;
    .category-product__menu__ul {
      .category-product__menu__li {
        padding-left: 10px;
        &:last-child {
          border-bottom: none;
        }
        .category-product__menu__li__sub {
          padding: 0;

          > ul {
            flex-direction: column;
            width: 259px;
            li {
              width: 100%;
              height: 51px;
              display: flex;
              align-items: center;
              padding-left: 29px;
              &:hover {
                background-color: #d4f4f4;
              }
            }
          }
        }

        .category-product__item {
          //display: flex;
          //align-items: center;
          height: auto;
          padding: 14px 0;

          p {
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            color: #000000;
          }
        }
      }
    }
  }
}
