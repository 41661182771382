@import 'var';

//reset css
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  color: $baseColorLink;
  text-decoration: none;
}

//css custom

.main-layout {
  display: flex;
  font-family: 'Archivo', sans-serif;
}

.main-layout__content {
  flex-grow: 1;
  width: calc(100% - 230px);
  max-width: 100%;
}

.wrap-container {
  margin: auto;
  max-width: 1611px;
  padding: 25px 15px;
  @media screen and (min-width: 1440px) {
    width: 85%;
  }
}

.no-margin-hoz {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

.product-slider-view {
  position: relative;

  .product-slider-view__btn {
    position: absolute;
    z-index: 5;
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    border: 2px solid #c4c4c4;
    background: #ffffff;

    &--next {
      top: 150px;
      right: -5px;
    }

    &--prev {
      top: 150px;
      left: -5px;
    }
  }
}

.star-list-view {
  display: flex;
  align-items: center;

  img {
    height: auto;
    object-fit: contain;
    display: block;
    margin-right: 1.5px;
  }
}

.review-comment-item {
  padding: 40px 0;
  border-bottom: 1px solid #d2cbcb;

  .review-comment-item__user {
    display: flex;
    align-items: center;

    img {
      width: 50px;
      height: 50px;
      border-radius: 50px;
      object-fit: cover;
      overflow: hidden;
      background: #c4c4c4;
      margin-right: 15px;
    }

    p {
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      letter-spacing: 0.1px;
      color: #181725;
      margin-bottom: 15px;
    }

    span {
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.1px;
      color: #7c7c7c;
    }
  }

  .review-comment-item__content {
    .review-comment-item__content__star {
      display: flex;
      align-items: center;
      padding-top: 15px;

      p {
        padding-left: 15px;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        letter-spacing: 0.1px;
        color: #000000;
      }
    }

    .review-comment-item__content__type-product {
      padding-top: 15px;
      font-size: 14px;
      line-height: 19px;
      color: #3f3b3b;
    }

    .review-comment-item__content__comment {
      padding-top: 15px;
      font-size: 14px;
      line-height: 19px;
      color: #828282;
    }

    .review-comment-item__content__image {
      padding-top: 15px;
      display: flex;
      align-items: center;

      img {
        width: 100px;
        height: 100px;
        object-fit: contain;
        margin-right: 15px;
      }
    }
  }
}

.switch-box {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 30px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 22px;
    width: 22px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #27ae60;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #27ae60;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 30px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

.select-check-box-item {
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #333333;
  border-radius: 5px;
  padding: 2px 0;

  &__content {
    font-size: 16px;
    line-height: 22px;
    color: #333333;
    padding: 0 15px;
    min-width: 125px;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border-left: 1px solid #333333;
    cursor: pointer;

    svg {
      font-size: 18px;
    }
  }
}

.select-check-box-item__dropdown {
  position: absolute;
  top: 42px;
  left: 0;
  right: 0;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  z-index: 10;
  padding: 15px 15px;
  display: none;

  &.show {
    display: block;
  }

  &__label {
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 10px;
  }
}

.select-check-box-item__dropdown__list {
  max-height: 350px;
  overflow-y: auto;
  margin: 0 -15px;

  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #ffffff;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #cdcaca;
    border-radius: 5px;
  }
}

.select-check-box-item__dropdown__list__item {
  display: flex;
  //align-items: center;
  align-items: flex-start;
  border-bottom: 1px solid #e0e0e0;
  padding: 10px 15px;
  cursor: pointer;

  &:last-child {
    border: none;
  }

  &__check-box {
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 22px;
    border: 1px solid #767474;
    background: $whiteColor;
    margin-right: 15px;

    svg {
      font-size: 10px;
      path {
        fill: $whiteColor;
      }
    }
  }

  p {
    flex: 1;
    font-size: 14px;
    line-height: 22px;
    color: #000000;
  }

  &.active,
  &:hover {
    .select-check-box-item__dropdown__list__item__check-box {
      background: $baseColorLight;
      border: 1px solid $baseColorLight;
    }

    svg {
      font-weight: bold;
      path {
        fill: $whiteColor;
      }
    }
  }
}

.datetime-picker-item {
  display: flex;
  align-items: center;
  background: rgba(32, 171, 173, 0.1);
  border: 1px solid #20abad;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px 10px;

  &__label {
    text-wrap: nowrap;
    font-size: 16px;
    line-height: 22px;
    color: #3f3b3b;
    padding-right: 10px;
    border-right: 1px solid #3f3b3b;
    background: transparent;
  }

  &__input {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #20abad;
    border: none;
    background: transparent;
    padding-left: 10px;
    width: 160px;

    &:focus {
      outline: none;
    }
  }

  input[type='date']::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 0);
    opacity: 1;
    display: block;
    background-image: url('../icons/icon-datepicker.png');
    background-repeat: no-repeat;
    width: 12px;
    height: 13px;
    border-width: thin;
  }

  i {
    color: #20abad;
    margin-left: 10px;
    font-size: 16px;
  }
}

.personal-voucher-search {
  background: #ffffff;
  box-shadow: 2px 2px 10px 3px rgba(0, 0, 0, 0.05);
  padding: 15px;
  overflow: hidden;

  &__input {
    display: flex;
    .btn-choose {
      text-wrap: nowrap;
      background-color: #f15b2e;
      margin-left: 10px;
    }
  }
  &__error {
    display: flex;
    padding: 15px 0 0;
  }
}

.personal-voucher-item {
  background: #ffffff;
  box-shadow: 2px 2px 10px 3px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  padding: 15px;
  display: flex;
  margin-top: 20px;
  position: relative;
  overflow: hidden;

  &__border {
    position: absolute;
    left: 180px;
    width: 30px;
    height: 15px;
    background-color: #ffffff;

    &--top {
      top: -5px;
      border-bottom-left-radius: 100px;
      border-bottom-right-radius: 100px;
      box-shadow: inset -3px -3px 10px 2px rgba(0, 0, 0, 0.05);
      -moz-box-shadow: inset -3px -3px 10px 2px rgba(0, 0, 0, 0.05);
      -webkit-box-shadow: inset -3px -3px 10px 2px rgba(0, 0, 0, 0.05);
    }

    &--bottom {
      bottom: -5px;
      border-top-left-radius: 100px;
      border-top-right-radius: 100px;
      box-shadow: inset 3px 3px 10px 1px rgba(0, 0, 0, 0.05);
      -moz-box-shadow: inset 3px 3px 10px 1px rgba(0, 0, 0, 0.05);
      -webkit-box-shadow: inset 3px 3px 10px 1px rgba(0, 0, 0, 0.05);
    }
  }

  &__icon-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // background: #3cb6b7;
    border-radius: 10px;
    padding: 10px;
    width: 160px;
    height: 120px;

    p {
      font-weight: 800;
      font-size: 25px;
      line-height: 34px;
      text-transform: uppercase;
      color: #ffffff;
      padding-top: 10px;
    }

    img {
      max-width: 165px;
    }
  }

  &--transfer {
    .personal-voucher-item__icon-block {
      background: #07844f;

      img {
        width: 80px;
      }

      p {
        font-weight: 800;
        font-size: 20px;
        line-height: 27px;
        text-align: center;
      }
    }
  }

  &--partner {
    .personal-voucher-item__icon-block {
      background: $whiteColor !important;

      p {
        font-weight: 800;
        font-size: 25px;
        line-height: 34px;
        color: #000000;
      }
    }
  }

  &--disable {
    .personal-voucher-item__icon-block {
      background: #bdbdbd;
    }
  }
}

.personal-voucher-item__content {
  margin-left: 20px;
  border-left: 1px dashed #a7a9ac;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  position: relative;

  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    width: 100%;

    p {
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      color: #333333;
      flex: 1;
    }

    img {
      display: block;
      margin-left: 10px;
      cursor: pointer;
    }
  }

  &__info {
    font-size: 14px;
    line-height: 19px;
    color: #333333;
    margin-bottom: 5px;
  }

  &__time {
    font-size: 14px;
    line-height: 19px;
    color: #767474;
  }

  &__time-expired {
    font-size: 14px;
    line-height: 19px;
    color: #ec1c24;
  }

  &__btn-wrap {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex: 1;
    width: 100%;

    &__btn-save {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #f15b2e;
      border: 1px solid #f15b2e;
      box-sizing: border-box;
      border-radius: 20px;
      background: $whiteColor;
      padding: 8px 20px;
      margin-bottom: 10px;
    }

    &__btn-use {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      background: #f15b2e;
      border-radius: 20px;
      color: #ffffff;
      padding: 8px 20px;
      border: none;
      margin-bottom: 10px;
    }
  }
}

.personal-voucher-item__content__detail {
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  top: -5px;
  z-index: 5;
  right: -10px;
  width: 325px;
  padding: 15px 15px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: none;

  &.active {
    display: block;
  }

  &__code {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    p {
      font-size: 14px;
      line-height: 19px;
      color: #767474;
      flex: 1;
    }

    span {
      font-size: 14px;
      line-height: 19px;
      color: #333333;
      padding: 0 10px;
    }

    img {
      cursor: pointer;
    }
  }

  &__time {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    &__label {
      font-size: 14px;
      line-height: 19px;
      color: #767474;
      flex: 1;
    }
  }

  &__condition-label {
    font-size: 14px;
    line-height: 19px;
    color: #767474;
    margin-bottom: 5px;
  }

  &__condition-info {
    display: flex;
    align-items: flex-start;
    margin-bottom: 5px;

    p {
      font-size: 14px;
      line-height: 14px;
      color: #333333;
      padding-left: 10px;
    }

    span {
      width: 10px;
      height: 10px;
      background: #fb800f;
      border-radius: 10px;
    }
  }
}

.common-page-breadcrumb {
  font-size: 16px;
  line-height: 20px;
  color: $baseColorStrong;
  padding-bottom: 25px;

  a {
    cursor: pointer;
    color: $baseColorStrong;
    text-decoration: none;

    &:hover {
      text-decoration: underline !important;
    }
  }
}

.pagination-custom {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 100%;

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #767474;
    box-sizing: border-box;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    font-size: 14px;
    line-height: 19px;
    cursor: pointer;
    margin: 0 5px;

    svg path {
      fill: #767474;
    }

    a {
      color: #767474;
    }

    &.active {
      background: #20abad;
      border: 1px solid #20abad;

      a {
        color: $whiteColor;
      }
    }
  }
}

.suggest-product {
  .suggest-product__title {
    position: relative;
    p {
      font-size: 1.875rem;
      font-weight: bold;
      line-height: 41px;
      border-bottom: 1px solid #d2cbcb;
      padding-bottom: 21px;
    }
    p::after {
      width: 205px;
      position: absolute;
      bottom: 0;
      left: 0;
      height: 3px;
      background-color: #008081;
      content: '';
    }
  }
  .suggest-product__products {
    display: flex;
    flex-wrap: wrap;
    .suggest-product__product {
      width: 20%;
    }
  }
  .suggest-product__list-product__preview {
    .suggest-product__list-product__preview__link {
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      p {
        padding: 17px 35px;
        border: 1px solid #20abad;
        border-radius: 50px;
        font-size: 1rem;
        color: #20abad;
        img {
          margin-left: 15px;
        }
      }
    }
  }
}

.page-product__related-section {
  padding-top: 40px;

  .related-section-title {
    border-bottom: 1px solid #d2cbcb;
    position: relative;
  }

  .related-section-title__text {
    font-weight: bold;
    font-size: 25px;
    line-height: 34px;
    color: $blackColor;
    padding-bottom: 20px;
  }

  .related-section-title__underline {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    height: 3px;
    width: 450px;
    background: $baseColorStrong;
  }
}

.page-product__breadcrumb {
  font-size: 16px;
  line-height: 20px;
  color: $baseColorLink;
  padding-bottom: 25px;

  a {
    cursor: pointer;
    color: $baseColorLink;
    text-decoration: none;

    &:hover {
      text-decoration: underline !important;
    }
  }
}

.branding-common-page-slider-brand {
  position: relative;

  &__item {
    height: 120px;
  }

  &__item__image {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0 30px;
    cursor: pointer;

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  }

  .brand-slider-view__btn {
    position: absolute;
    z-index: 5;
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    border: 2px solid #c4c4c4;
    background: #ffffff;

    i {
      color: #c4c4c4;
      font-size: 14px;
    }

    &--next {
      top: 60px;
      right: -5px;
    }

    &--prev {
      top: 60px;
      left: -5px;
    }
  }
}

.result-not-found {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  // margin-top: 50px;
  img {
    width: 200px;
  }
  span {
    font-weight: bold;
  }
}

.mt-10 {
  margin-top: 4rem !important;
}

.mt-5 {
  margin-top: 2rem !important;
}

.mt-6 {
  margin-top: 2.5rem !important;
}

.mt-7 {
  margin-top: 3rem !important;
}

.font-weight-bold {
  font-weight: bold;
}

.flyingBtn {
  z-index: 9999;
  transform-origin: center bottom;
  transform: scale(1);
}
